/**
 * 工作流程 列表
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Select, Option, Message, Page, Modal,Button,Card,Dropdown,DropdownMenu,DropdownItem,Spin } from "view-design";
import {
    getProcessListService,
    delProcessListService,
    updateProcessListNameService,
    processDate,
    processStatus, updateWorkFlowStatusService
} from "@/service/workflow-service";
import { mapMutations, mapState } from "vuex";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Card', Card);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Spin', Spin);
const ProcessList = {
    name:'ProcessList',
    computed: {
        ...mapState({
            menuVisible: state => state.system.menuVisible, //菜单显示状态
        }),
    },
    data() {
        return {
            processLoading:false, //loading
            processDate:processDate, //流程时间分类
            processStatus:processStatus, //流程状态
            processListArray:{}, //列表数据
            processName:'', //工作流名称
            processHeight:0,
            processListParams:{ //提交的数据集
                sortType:'', //时间排序
                status:'', //流程状态
                currentPage: 1,  //当前页
                displayNumber:9 // 显示条数
            },
        }
    },
    created() {
        this.onProcessList().then()
        this.onTableHeight()
    },
    mounted() {
        window.addEventListener('resize', this.onTableHeight)
    },
    methods: {
        ...mapMutations('system',['SET_FLOW_ID']),
        /**
         * 计算table高度
         */
        onTableHeight(){
            this.processHeight = window.innerHeight - 173
            this.windowWidth = window.innerWidth
        },
        /**
         * 搜索
         */
        onSearch(){
            this.onProcessList().then()
        },
        /**
         * 新增
         */
        onAddList() {
            this.SET_FLOW_ID('')
            this.$router.push('/workflow/config')
        },
        /**
         * 修改名称状态
         * @param key
         */
        onUpdateNameStatus(key,obj) {
            if(obj.publishUseNum > 0){
                Message.warning('此流程已经被工作任务引用，不可重命名！')
                return
            }
            this.processListArray.content[key].nameStatus = true
            this.processName = obj.flowName
            this.$nextTick(()=>{
                this.$refs['flowName'][0].focus();
            })
        },
        /**
         * 跳转工作流配置
         * @param obj
         */
        onEditLinks(obj) {
            this.SET_FLOW_ID(obj.id)
            this.$router.push('/workflow/config')
        },
        /**
         * 修改名称
         * @param key
         */
        onUpdateName(id,key){
            this.processListArray.content[key].nameStatus = false
            if(this.processName === this.processListArray.content[key].flowName){
                return
            }
            if(this.processListArray.content[key].flowName === ''){
                Message.warning('请输入名称！')
                this.processListArray.content[key].nameStatus = true
                this.$nextTick(()=>{
                    this.$refs['flowName'][0].focus();
                })
                return
            }
            this.onUpdateProcessListName(id,key).then()
        },
        /**
         * 删除
         * @param obj
         */
        onDelete(obj) {
            if(obj.publishUseNum > 0){
                Message.warning('此流程已经被工作任务引用，不可删除！')
                return
            }
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelProcessList(obj.id).then()
                }
            });
        },
        /**
         * 发布
         */
        onRelease(id,status) {
            this.onUpdateWorkFlowStatus(id,status).then()
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.processListParams.currentPage = number
            this.onProcessList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.processListParams.displayNumber = number
            this.onProcessList().then()
        },
        /**
         * 获取工作流程列表
         */
        async onProcessList() {
            try {
                this.processLoading = true
                this.processListArray = {}
                let params = {
                    sortType:this.processListParams.sortType,
                    status:this.processListParams.status,
                    size:this.processListParams.displayNumber,
                    page:this.processListParams.currentPage
                }
                const { code,data } = await getProcessListService(params)
                if(code === 'SUCCESS'){
                    for(let i in data.content){
                        data.content[i].nameStatus = false
                    }
                    this.processListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.processLoading = false
            }catch (error){
                this.processLoading = false
                console.log(error)
            }
        },
        /**
         * 删除任务列表
         */
        async onDelProcessList(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await delProcessListService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.onProcessList().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改工作流名称
         */
        async onUpdateProcessListName(id,key) {
            try {
                let params = {
                    id:id,
                    flowName:this.processListArray.content[key].flowName
                }
                const { code } = await updateProcessListNameService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功！')
                    this.processListArray.content[key].nameStatus = false
                }else if(code === 'WORK_FOLW_NAME_IS_REPEAT'){
                    Message.warning('工作流名称不能与其他工作流名称相同！')
                    this.processListArray.content[key].nameStatus = true
                    this.$nextTick(()=>{
                        this.$refs['flowName'][0].focus();
                    })
                }else{
                    this.processListArray.content[key].nameStatus = true
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 工作流更新发布状态
         */
        async onUpdateWorkFlowStatus(id,status) {
            try {
                let params = {
                    id:id,
                    status:status
                }
                const { code } = await updateWorkFlowStatusService(params)
                if(code === 'SUCCESS'){
                    if(status === 1 ){
                        Message.success('发布成功！')
                    }
                    if(status === 0 ){
                        Message.success('撤回成功！')
                    }
                    for(let i in this.processListArray.content){
                        if(id === this.processListArray.content[i].id){
                            this.processListArray.content[i].status = status
                        }
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
    },
    destroyed () {
        window.removeEventListener('resize',this.onTableHeight)
    }
}
export default ProcessList
